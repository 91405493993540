import React, { useState } from "react";
import axios from "axios";
import { toast, Slide } from "react-toastify";
import { PatternFormat } from "react-number-format";
import { PreLaunchHero } from "../../components/Hero/Hero";
import { Title, Subtitle } from "../../components/Title/Title";
import { Button } from "../../components/Button/Button";
import { Apps } from "../../components/Apps/Apps";
import tireoide from "../../assets/img-sistema/svg/icon-tireoide.svg";
// import mioma from "../../assets/img-sistema/prelaunch/icon-mioma.svg";
import endometriose from "../../assets/img-sistema/prelaunch/icon-endometriose.svg";
import agenda from "../../assets/img-sistema/prelaunch/icon-agenda.svg";
import face from "../../assets/img-sistema/prelaunch/icon-face.svg";
import laudario from "../../assets/img-sistema/prelaunch/icon-laudario.svg";
import mama from "../../assets/img-sistema/prelaunch/icon-mama.svg";
import varizes from "../../assets/img-sistema/prelaunch/icon-varizes.svg";
import check from "../../assets/img-sistema/prelaunch/icon-check.svg";

import classes from "../../layout/pages/_prelaunch.module.scss";

export const BetaTest = () => {
  const [formData, setFormData] = useState({
    origin: "BETA_TEST_V1",
    optIn: "YES",
  });
  const [status, setStatus] = useState("pending");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://api.uway.med.br/contacts", formData);
      setStatus("sent");
      notify("ok");
    } catch (error) {
      setStatus("error");
      notify("error");
    }
  };

  const notify = (status) => {
    if (status === "ok") {
      return toast.success("Cadastro para o teste beta concluído!", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    return toast.error(
      "Ooooops, algo deu errado... sentimos muito, por favor tente novamente mais tarde!",
      {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      }
    );
  };

  const pendingStatus = () => (
    <>
      <h1>FAÇA SEU CADASTRO PARA O TESTE BETA!</h1>
      <h2>
        Use a versão inicial do nosso App por um mês e <br /> responda um
        questionário de avaliação ao final deste período <br /> para obter um
        super {""}
        <span>DESCONTO</span> no lançamento. <br /> <br />
        Ao se cadastrar você receberá um email com link, e senha para acesso.
      </h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          id="name"
          name="name"
          required
          onChange={handleChange}
          placeholder="Nome"
        />
        <input
          type="email"
          id="email"
          name="email"
          required
          onChange={handleChange}
          placeholder="Email"
        />
        <PatternFormat
          id="phone"
          name="phone"
          required
          placeholder="Telefone WhatsApp"
          format="(##) #####-####"
          valueIsNumericString
          onChange={handleChange}
        />
        <Button
          text="CADASTRAR"
          type="submit"
          prereg="true"
          btcolor="prelaunch"
        />
      </form>
    </>
  );

  const sentStatus = () => (
    <>
      <h1>Obrigado por participar da fase de avaliação!</h1>
      {/* <h2>
        CONCORRA A CHANCE DE OBTER UM VOUCHER DE <span>DESCONTO</span> NO
        LANÇAMENTO
      </h2> */}

      <img src={check} alt="Check icon" />

      <h3>Seu cadastro para o teste BETA foi realizado.</h3>
      <p>
        Obrigado por participar da fase de avaliação! Dentro de uma semana a{" "}
        <i>uway</i> enviará o seus dados de acesso por e-mail.
        <br />
        <br />
        <strong>Parabéns! </strong>Você está entre os primeiros médicos a se
        destacarem no mercado por possuirem essa elegante ferramenta de laudo
        gráfico.
      </p>
    </>
  );

  const errorStatus = () => (
    <>
      <h1>Houve algum erro de sistema!</h1>
      <h2>Por favor, recarregue seu navegador e tente novamente.</h2>
      <h2>Desculpe pelo transtorno.</h2>
    </>
  );

  const statusHandler = (status) => {
    const handler = {
      pending: pendingStatus(),
      sent: sentStatus(),
      error: errorStatus(),
    };

    return handler[status] || handler.pending;
  };

  return (
    <div>
      <section id="PreLaunchHero">
        <PreLaunchHero
          title="FAÇA SEU CADASTRO E AVALIE A VERSÃO BETA! "
          subTitle="GANHE UM VOUCHER DE DESCONTO NO LANÇAMENTO"
        />
      </section>

      <section id="Apps" className={classes.prelaunch__app}>
        <Title text="NOSSOS APPS" ready={"true"} />
        <Subtitle text="DE CARTOGRAFIA MÉDICA" />
        <div className={classes.prelaunch__app__dev}>
          <Apps text="TIREOIDE" icon={tireoide} />
          {/* <Apps text="MAPEAMENTO DE MIOMAS" icon={mioma} /> */}
        </div>
        <div className={classes.prelaunch__app__animations}>
          <div>
            <iframe
              src="/Animacoes/Step-01-Animation/Step-01_HTML5 Canvas.html"
              title="animacao"
              style={{ border: "none" }}
            ></iframe>
            <h3>
              Insira as informações <br /> do exame de imagem
            </h3>
          </div>
          <div>
            <iframe
              src="/Animacoes/Step-02-Animation/Step-02_HTML5 Canvas.html"
              title="animacao"
              style={{ border: "none" }}
            ></iframe>
            <h3>
              Faça ajustes e gere <br />
              pré-laudos automáticos
            </h3>
          </div>
          <div>
            <iframe
              src="/Animacoes/Step-03-Animation/Step-03_HTML5 Canvas.html"
              title="animacao"
              style={{ border: "none" }}
            ></iframe>
            <h3>
              Envie em pdf ou imprima
              <br /> belas imagens
            </h3>
          </div>
          <div>
            <iframe
              src="/Animacoes/Step-04-Animation/Step-04_HTML5 Canvas.html"
              title="animacao"
              style={{ border: "none" }}
            ></iframe>
            <h3>
              Junte ao exame de
              <br /> imagem e impressione!
            </h3>
          </div>
        </div>
        <Title text="APPS EM DESENVOLVIMENTO" ready={"false"}></Title>
        <div className={classes.prelaunch__app__dev}>
          <Apps text="GINECOLÓGICO" icon={endometriose} ready="false" />
          <Apps text="FACE" icon={face} ready="false" />
          <Apps text="MAMA" icon={mama} ready="false" />
          <Apps text="VARIZES DA PERNA" icon={varizes} ready="false" />
          <Apps text="AGENDA" icon={agenda} ready="false" />
          <Apps text="LAUDÁRIO" icon={laudario} ready="false" />
        </div>
      </section>
      <section
        id="PreRegistration"
        className={classes.prelaunch__preregistration}
      >
        {statusHandler(status)}
      </section>
    </div>
  );
};
