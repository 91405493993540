import React from "react";
import { Hero } from "../components/Hero/Hero";
import { Apps } from "../components/Apps/Apps";
import { Title } from "../components/Title/Title";
import { SignCardFree, SignCard } from "../components/SignCard/SignCard";
import { NavBar } from "../components/NavBar/NavBar";
import logo from "../assets/img-sistema/svg/logo-uway.svg";
import tireoide from "../assets/img-sistema/svg/icon-tireoide.svg";
// import mioma from "../assets/img-sistema/svg/icon-mioma.svg";
import face from "../assets/img-sistema/svg/icon-face.svg";
import varizes from "../assets/img-sistema/svg/icon-varizes.svg";
import mama from "../assets/img-sistema/svg/icon-mama.svg";
import pic1 from "../assets/img-sistema/pic1.png";
import pic2 from "../assets/img-sistema/pic2.png";
import pic3 from "../assets/img-sistema/pic3.png";
import email from "../assets/img-sistema/svg/email.svg";
import youtube from "../assets/img-sistema/svg/youtube.svg";
import instagram from "../assets/img-sistema/svg/instagram.svg";
import classes from "../layout/pages/_home.module.scss";

export const Home = () => {
  return (
    <div className={classes.home}>
      <NavBar />

      <section id="Start">
        <Hero />
      </section>

      <section id="Apps" className={classes.home__app}>
        <Title text="NOSSOS APPS" ready={"true"} />
        <div className={classes.home__app__prod}>
          <Apps text="TIREOIDE" icon={tireoide} ready={"true"} />
          {/* <Apps text="MAPEAMENTO DE MIOMAS" icon={mioma} ready={"true"} /> */}
        </div>
        <Title text="APPS EM DESENVOLVIMENTO" ready={"false"}></Title>
        <div className={classes.home__app__dev}>
          <Apps text="MAMA" icon={mama} ready={"false"} />
          <Apps text="FACE" icon={face} ready={"false"} />
          <Apps text="VARIZES DA PERNA" icon={varizes} ready={"false"} />
          <Apps text="ESTÔMAGO" icon={mama} ready={"false"} />
        </div>
      </section>

      <section id="ComoFunciona" className={classes.home__video}>
        {/* <video></video> */}
      </section>

      <section id="Assinar" className={classes.home__sign}>
        <Title text="ESCOLHA SEU PLANO" ready={"true"} />
        <div className={classes.home__sign__apps}>
          <SignCardFree title="TEST" btcolor="gray" bgcolor="gray" />
          <SignCard
            title="STANDARD"
            nApps="2"
            btcolor="lightBlue"
            bgcolor="lightBlue"
            preco1="90,00"
            preco2="240,00"
            preco3="690,00"
          />
          <SignCard
            title="ADVANCED"
            nApps="4"
            btcolor="darkBlue"
            bgcolor="darkBlue"
            preco1="120,00"
            preco2="290,00"
            preco3="840,00"
          />
          <SignCard
            title="GLOBAL"
            nApps="6"
            btcolor="purple"
            bgcolor="purple"
            preco1="160,00"
            preco2="420,00"
            preco3="990,00"
          />
        </div>
      </section>

      <section id="Screens" className={classes.home__screens}>
        <Title text="SCREENS" ready={"true"} />
        <div className={classes.home__screens__row}>
          <div className={classes.home__screens__row__column}>
            <img src={pic1} alt="App demo" />
            <img src={pic3} alt="App demo" />
          </div>
          <div className={classes.home__screens__row__column}>
            <img src={pic2} alt="App demo" />
            <img src={pic1} alt="App demo" />
          </div>
          <div className={classes.home__screens__row__column}>
            <img src={pic3} alt="App demo" />
            <img src={pic2} alt="App demo" />
          </div>
        </div>
      </section>
      <section id="Contato" className={classes.home__footer}>
        <img src={logo} className={classes.home__footer__logo} alt="Logo" />
        <div className={classes.home__footer__nav}>
          <a href="#Apps">Apps</a>
          <a href="#ComoFunciona">Como Funciona?</a>
          <a href="#Assinar">Assinar</a>
          <a href="#Screens">Screens</a>
        </div>
        <div className={classes.home__footer__media}>
          <div className={classes.home__footer__media__row}>
            <img src={email} alt="Email" />
            <span> contato@uway.com</span>
          </div>
          <div className={classes.home__footer__media__row}>
            <img src={youtube} alt="Youtube" />
            <span> @uway</span>
          </div>
          <div className={classes.home__footer__media__row}>
            <img src={instagram} alt="Instagram" />
            <span> /uway</span>
          </div>
        </div>
      </section>
    </div>
  );
};
