import React from "react";
import { Button } from "../../components/Button/Button";
import logo from "../../assets/img-sistema/svg/logo-uway.svg";
import classes from "./_hero.module.scss";

export const Hero = () => {
  return (
    <div className={classes.hero}>
      <img src={logo} className={classes.hero__logo} alt="Logo" />
      <h1 className={classes.hero__title}>
        <i>
          Crie imagens ilustrativas <br /> aplicadas a ultrassonografia
        </i>
      </h1>
      <p className={classes.hero__subtitle}>
        De forma rápida e fácil você edita e agrega as imagens que faltavam em
        seu diagnóstico
        <strong> e se destaca como profissional!</strong>
      </p>
      <Button
        text="ASSINAR"
        type="button"
        hero="true"
        btcolor="lightBlue"
        href="#Assinar"
      />
      <a className={classes.hero__next} href="#ComoFunciona">
        Como funciona?
      </a>
    </div>
  );
};

export const PreLaunchHero = ({ title, subTitle }) => {
  const scrollToBottom = () => {
    console.log("smooth");
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <div className={classes.hero} prelaunch="true">
      <img src={logo} className={classes.hero__logo} alt="Logo" />
      <h1 className={classes.hero__title}>
        <i>
          Crie imagens ilustrativas <br /> aplicadas a ultrassonografia
        </i>
      </h1>
      <p className={classes.hero__subtitle}>
        De forma rápida e fácil você edita e agrega as imagens de cartografia
        que faltavam em seu diagnóstico e se
        <strong> destaca como profissional!</strong>
      </p>

      <div className={classes.hero__discountBox} onClick={scrollToBottom}>
        {title}
        <br /> {subTitle}
      </div>
    </div>
  );
};
